<template>
  <div>
    <b-modal
      id="order-payment-wiretransfer-modal-2"
      ref="order-payment-wiretransfer-modal-2"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="pb-4 text-black"
    >
      <CLink @click="hide()">
        <CIcon name="cil-x" class="popup-close-btn" size="lg" />
      </CLink>
      <div class="modal-title-box">
        <div class="modal-title font-weight-bold">{{ $t("payment.wireTransfer.popupTitle") }}</div>
        <div class="modal-subtitle">
          {{ $t("payment.wireTransfer.popupDescription") }}
        </div>
      </div>

      <div class="payment-info-box">
        <span>{{ $t("payment.wireTransfer.orderNumber") }} : {{ wireTransferInfo.moid }}</span>
        <span>{{ $t("payment.wireTransfer.orderDate") }} : {{ formatDateTime(wireTransferInfo.orderDate) }}</span>
      </div>

      <div class="artwork-details ">
        <div class="d-flex">
          <div class="left">
            <div class="font-weight-bold small-space">{{ $t("payment.wireTransfer.artworkName") }}</div>
            <div class="space">{{ activeLanguage === 'ko' ? orderPaymentInfo.productName : orderPaymentInfo.productNameEn }}</div>
            <div class="artwork-card">
              <img :src="replaceDisplayDetailUrl(paymentProductInfo)" class="artwork-thumbnail" />
            </div>
          </div>
          <div>
            <div class="font-weight-bold small-space">{{ $t("payment.wireTransfer.paymentInfo") }}</div>
            <div class="artwork-card">
              <div class="d-flex flex-column">
                <span class="space">{{ $t("payment.wireTransfer.depositInfo") }}</span>
                <span class="small text-muted" v-html="
                  $t('payment.wireTransfer.depositDescription1', {
                    year: getExpYear(),
                    month: getExpMonth(),
                    day: getExpDay(),
                    time: getExpTime()
                  })
                "></span>
              </div>
            </div>
          </div>
        </div>

        <div class="payment-details d-flex">
          <div>
            <div class="title left">{{ $t("payment.wireTransfer.depositPrice") }}</div>
            <div class="value">{{ orderPaymentInfo.productPrice | currency }}원</div>
          </div>
          <div>
            <div class="title">{{ $t("payment.wireTransfer.cashReceipt") }}</div>
            <div class="value">{{ $t("payment.wireTransfer.cashReceiptType", { number: personalInfo.mobile }) }}</div>
          </div>
          <div></div>
        </div>
      </div>

      <div class="button-wrapper">
        <Button
          :label="$t('payment.wireTransfer.goToProfile')"
          :onClick="hide"
          :isWhite="true"
        />
        <Button
          :label="$t('payment.wireTransfer.goToDrops')"
          :onClick="hide"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { createNamespacedHelpers } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";

const authHelper = createNamespacedHelpers("auth");
const paymentHelper = createNamespacedHelpers("payment");

export default {
  name: "PaymentWireTransfer2",
  components: {
    Button,
  },
  props: {
    paymentProductInfo: {
      type: Object,
      default: () => {},
    },
    orderPaymentInfo: {
      type: Object,
      default: () => {},
    },
    memberInfo: {
      type: Object,
      default: () => {},
    },
    personalInfo: {
      type: Object,
      default: () => {},
    },
    wireTransferInfo: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
  data: function () {
    return {
      paymentInfo: this.getInitPaymentInfo(),
      receiptTypeOptions: [
        { value: "1", text: this.$t("payment.vbank2.incomeDeduction") },
        { value: "2", text: this.$t("payment.vbank2.proopOfExpense") },
      ],
      receiptMethodOptions: [
        { value: "0", text: this.$t("payment.vbank2.select") },
        { value: "1", text: this.$t("payment.vbank2.mobile") },
        { value: "2", text: this.$t("payment.vbank2.corpNumber") },
      ],
      receiptMethod: "1",
      accept: false,
      showReceiptMethod: true,
      paymentResultInfo: {},
    };
  },
  computed: {
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
    isValid() {
      return !this.$v.paymentInfo.$invalid;
    },
    isDirty() {
      return this.$v.paymentInfo.$anyDirty;
    },
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  mixins: [validationMixin],
  validations() {
    if (this.paymentInfo.receiptType === "0") {
      return {
        paymentInfo: {
          buyerEmail: { required, email },
        },
      };
    } else {
      return {
        paymentInfo: {
          receiptTypeNo: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(11),
            numeric,
          },
          buyerEmail: { required, email },
        },
      };
    }
  },
  mounted() {},
  methods: {
    ...authHelper.mapActions(["verifyTokenCallback"]),
    ...paymentHelper.mapActions(["getPaymentVbank", "addPaymentVbank"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    requestPaymentInfo() {
      if (
        this.orderPaymentInfo.idxProduct !== undefined &&
        this.orderPaymentInfo.idxProduct !== null &&
        this.orderPaymentInfo.idxProduct !== ""
      ) {
        this.getPaymentVbank(this.orderPaymentInfo.idxProduct).then((data) => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            this.paymentInfo = data;
            this.paymentInfo.receiptType = "1"; // 소득공제용
          }
        });
      }
    },
    onChangeReceiptType() {
      this.receiptMethod = this.paymentInfo.receiptType;
      if (this.paymentInfo.receiptType === "0") {
        this.showReceiptMethod = false;
      } else {
        this.showReceiptMethod = true;
      }
    },
    onClickPayment() {
      //this.$log.log("[onClickPayment] >> ", this.paymentInfo);

      this.$v.$touch();
      if (this.isValid) {
        this.verifyTokenCallback()
          .then(() => {
            if (
              this.me === undefined ||
              this.me === null ||
              this.me.idx === undefined ||
              this.me.idx === ""
            ) {
              // 로그인
              alert("로그인 후 구매가능합니다. 로그인 후 다시 시도해주세요.");
            } else {
              // 결제하기
              this.addPaymentVbank(this.paymentInfo)
                .then((data) => {
                  //this.$log.log("[addPaymentVbank] >> ", data);
                  if (
                    data !== undefined &&
                    data !== null &&
                    data.resultCode !== undefined &&
                    data.resultCode !== null
                  ) {
                    if (data.resultCode === "4120") {
                      // 결제완료 화면 이동
                      this.paymentResultInfo = data;
                      this.$refs["paymentVBank3"].show();
                      this.$refs["order-payment-wiretransfer-modal-2"].hide();

                      // 판매 법인 정보 삭제
                      localStorage.removeItem(
                        "referralCorpSeller_" +
                          this.paymentResultInfo.idxProduct
                      );
                    } else {
                      alert(
                        "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
                      );
                      //TODO 화면 확인을 위해...추후삭제
                      //this.paymentResultInfo = data;
                      //this.$refs["paymentVBank3"].show();
                      //this.$refs["order-payment-modal-2"].hide();
                    }
                  } else {
                    alert(
                      "요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요."
                    );
                  }
                })
                .catch((error) => {
                  this.$log.error(error);
                  if (error.data.error === "NOT_FOUND") {
                    if (error.data.resource === "PRODUCT_FORSALE") {
                      alert("구매 가능한 작품이 존재하지 않습니다.");
                    } else if (error.data.resource === "ENTITY") {
                      alert("사용자(회원) 정보가 존재하지 않습니다.");
                    } else if (error.data.resource === "VBANK_EXPIRE") {
                      alert(
                        "가상계좌 입금시간이 만료되었습니다. 다시 시도해 주세요."
                      );
                    } else {
                      alert(
                        "주문결제 오류가 발생하였습니다. 관리자에게 문의하세요."
                      );
                    }
                  } else {
                    alert(
                      "주문결제 오류가 발생하였습니다. 관리자에게 문의하세요."
                    );
                  }
                });
            }
          })
          .catch((error) => {
            this.$log.error(error);
          });
      }
    },
    show() {
      console.log("show");
      this.getInitForm();
      // this.requestPaymentInfo();
      this.$refs["order-payment-wiretransfer-modal-2"].show();
    },
    hide() {
      this.$refs["order-payment-wiretransfer-modal-2"].hide();
    },
    checkIfValid(fieldName) {
      const field = this.$v.paymentInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    getInitForm() {
      this.paymentInfo = this.getInitPaymentInfo();
      this.accept = false;
      this.receiptMethod = "1";
      this.showReceiptMethod = true;
      this.paymentResultInfo = {};
    },
    getInitPaymentInfo() {
      return {
        idx: null,
        idxProduct: null,
        idxMember: null,
        vbankAccountName: null,
        vbankExpDatetime: null,
        buyerName: null,
        receiptType: "1",
        receiptTypeNo: null,
        buyerEmail: null,
      };
    },
    formatDateTime(dateString) {
      if (!dateString) return '';
      const d = new Date(dateString);
      return d.getFullYear() + '-' + 
             String(d.getMonth() + 1).padStart(2, '0') + '-' +
             String(d.getDate()).padStart(2, '0') + ' ' +
             String(d.getHours()).padStart(2, '0') + ':' +
             String(d.getMinutes()).padStart(2, '0') + ':' +
             String(d.getSeconds()).padStart(2, '0');
    },
    getExpYear() {
      return this.wireTransferInfo.vbankExpDate ? this.wireTransferInfo.vbankExpDate.substring(0, 4) : '' ;
    },
    getExpMonth() {
      return this.wireTransferInfo.vbankExpDate ? this.wireTransferInfo.vbankExpDate.substring(4, 6) : '';
    },
    getExpDay() {
      return this.wireTransferInfo.vbankExpDate ? this.wireTransferInfo.vbankExpDate.substring(6, 8) : '';
    },
    getExpTime() {
      return this.wireTransferInfo.vbankExpTime ? `${this.wireTransferInfo.vbankExpTime.substring(0, 2)}:${this.wireTransferInfo.vbankExpTime.substring(2, 4)}` : '';
    },
    replaceDisplayDetailUrl(item) {
      if (
        item.displayPosterUrl !== undefined &&
        item.displayPosterUrl !== null &&
        item.displayPosterUrl !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayPosterUrl !== undefined &&
          item.displayPosterUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayPosterUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayPosterUrl;
      }
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
