var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"order-payment-wiretransfer-modal-2",attrs:{"id":"order-payment-wiretransfer-modal-2","size":"lg","centered":"","hide-header":"","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","body-class":"pb-4 text-black"}},[_c('CLink',{on:{"click":function($event){return _vm.hide()}}},[_c('CIcon',{staticClass:"popup-close-btn",attrs:{"name":"cil-x","size":"lg"}})],1),_c('div',{staticClass:"modal-title-box"},[_c('div',{staticClass:"modal-title font-weight-bold"},[_vm._v(_vm._s(_vm.$t("payment.wireTransfer.popupTitle")))]),_c('div',{staticClass:"modal-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("payment.wireTransfer.popupDescription"))+" ")])]),_c('div',{staticClass:"payment-info-box"},[_c('span',[_vm._v(_vm._s(_vm.$t("payment.wireTransfer.orderNumber"))+" : "+_vm._s(_vm.wireTransferInfo.moid))]),_c('span',[_vm._v(_vm._s(_vm.$t("payment.wireTransfer.orderDate"))+" : "+_vm._s(_vm.formatDateTime(_vm.wireTransferInfo.orderDate)))])]),_c('div',{staticClass:"artwork-details"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"font-weight-bold small-space"},[_vm._v(_vm._s(_vm.$t("payment.wireTransfer.artworkName")))]),_c('div',{staticClass:"space"},[_vm._v(_vm._s(_vm.activeLanguage === 'ko' ? _vm.orderPaymentInfo.productName : _vm.orderPaymentInfo.productNameEn))]),_c('div',{staticClass:"artwork-card"},[_c('img',{staticClass:"artwork-thumbnail",attrs:{"src":_vm.replaceDisplayDetailUrl(_vm.paymentProductInfo)}})])]),_c('div',[_c('div',{staticClass:"font-weight-bold small-space"},[_vm._v(_vm._s(_vm.$t("payment.wireTransfer.paymentInfo")))]),_c('div',{staticClass:"artwork-card"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"space"},[_vm._v(_vm._s(_vm.$t("payment.wireTransfer.depositInfo")))]),_c('span',{staticClass:"small text-muted",domProps:{"innerHTML":_vm._s(
                _vm.$t('payment.wireTransfer.depositDescription1', {
                  year: _vm.getExpYear(),
                  month: _vm.getExpMonth(),
                  day: _vm.getExpDay(),
                  time: _vm.getExpTime()
                })
              )}})])])])]),_c('div',{staticClass:"payment-details d-flex"},[_c('div',[_c('div',{staticClass:"title left"},[_vm._v(_vm._s(_vm.$t("payment.wireTransfer.depositPrice")))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("currency")(_vm.orderPaymentInfo.productPrice))+"원")])]),_c('div',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("payment.wireTransfer.cashReceipt")))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.$t("payment.wireTransfer.cashReceiptType", { number: _vm.personalInfo.mobile })))])]),_c('div')])]),_c('div',{staticClass:"button-wrapper"},[_c('Button',{attrs:{"label":_vm.$t('payment.wireTransfer.goToProfile'),"onClick":_vm.hide,"isWhite":true}}),_c('Button',{attrs:{"label":_vm.$t('payment.wireTransfer.goToDrops'),"onClick":_vm.hide}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }